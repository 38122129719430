import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { Card, Container } from "react-bootstrap";
import CommonModal from "../../../components/CommonModal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import TextBoxLive from "../../../components/TextBoxLive";
import SelectBox from "../../../components/Selectbox";
import Button from "../../../components/Button";
import UserService from "../../../services/UserService";
import { useLazyFetchDistrictsQuery } from "../../../services/modules/admin/district";
import { useAddUserMutation } from "../../../services/modules/admin/users";
import { useLazyFetchRolebyRoleCodeQuery } from "../../../services/modules/admin/role";

import FileSelector from "../../../components/FileSelector";
import Loader from "../../../components/Loader";
import { validationSchema } from "./validationSchema";
import { millisToMinutesAndSeconds } from "../../../utils/utility";
import { useLazyGetOtpQuery } from "../../../services/modules/otp";
import OTPInput from "react-otp-input";
import { ownershipTypes } from "../../../utils/constants";
import { uploadFilesInSchema } from "../../../utils/uploadFilesInSchema";
import { useLazyFetchStateQuery } from "../../../services/modules/admin/state";
import { useLazyFetchTypeOfOwnwershipQuery } from "../../../services/modules/ExistingVessel";

function Index(props) {
  const [userInfo, setUserInfo] = useState(null);
  const [roleCode, setRoleCode] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [typeOfOwenership, setTypeOfOwnership] = useState("");
  const [typeOfOwernshipVal, setTypeOfOwnerShipVal] = useState("");

  const [fetchDistricts, { data: districtData }] = useLazyFetchDistrictsQuery();
  const [fetchState, { data: stateData, isLoading: stateLoading }] =
    useLazyFetchStateQuery();
  const [fetchRolebyRoleCode, { data: roleData }] =
    useLazyFetchRolebyRoleCodeQuery();
  const [
    getOtp,
    {
      isSuccess: isGetOtpSuccess,
      data: getOtpData,
      isUninitialized: isGetOtpUninitialized,
    },
  ] = useLazyGetOtpQuery();
  const [fetchTypeOfOwnwership, { data: typeofOwner }] =
    useLazyFetchTypeOfOwnwershipQuery();

  useEffect(() => {
    fetchState({});
  }, [fetchState]);

  useEffect(() => {
    fetchTypeOfOwnwership({});
  }, []);

  useEffect(() => {
    const matchedTypeOfOwneship = typeofOwner?.result?.find(
      (item) => item?.name === typeOfOwernshipVal
    );

    if (matchedTypeOfOwneship) {
      setTypeOfOwnership(matchedTypeOfOwneship?.code);
    }
  }, [typeofOwner, typeOfOwernshipVal]);

  useEffect(() => {
    const getUserInfo = async () => {
      const userDetail = await UserService.getUserProfile();
      if (userDetail) {
        setUserInfo(userDetail);
      }
    };
    getUserInfo();
  }, []);

  useEffect(() => {
    if (userInfo) {
      console.log("userInfo", userInfo);
      getRoleCode();
    }
  }, [userInfo]);

  useEffect(() => {
    if (roleCode.length > 0) {
      fetchRolebyRoleCode({ roleCode: roleCode });
    }
  }, [roleCode]);

  const [addUser] = useAddUserMutation();

  const [message, setMessage] = useState({
    title: "",
    isSuccess: true,
    show: false,
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    setIsSubmitting(true);
    const {
      mobile,
      houseName,
      districtId,
      stateId,
      city,
      postOffice,
      pinCode,
      temporaryAddress,
      aadharNo,
      registrationNo,
      aadharFile,
      profilePic,
      typeOfOwnership,
      gstNum, // Assuming gstNum is the field for headofinstitutionNo
      signature,
      otp,
    } = values;

    const typeOfOwnershipCode = typeofOwner?.result?.find(
      (item) => item.name === typeOfOwnership
    )?.code;

    const postData = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      roleId: roleData?.result._id,
      status: true,
      uid: userInfo.id,
      otpId: isGetOtpSuccess ? getOtpData.result : "",
      mobile,
      houseName,
      districtId,
      stateId,
      city,
      postOffice,
      pinCode,
      temporaryAddress,
      aadharFile,
      typeOfOwnership: typeOfOwnershipCode,
      signature,
      otp,

      ...(gstNum && { headofinstitutionNo: gstNum }), // Only include if gstNum exists
      ...(registrationNo && { registrationNo: registrationNo }), // Only include if registrationNo exists
      ...(aadharNo && { aadharNo: aadharNo }), // Only include if aadharNo exists
      ...(profilePic && { profilePic: profilePic }),
    };

    const uploadedPostData = await uploadFilesInSchema(postData);

    try {
      const response = await addUser(uploadedPostData);

      if (response?.error) {
        setMessage({
          title: response?.error?.data?.message,
          show: true,
          isSuccess: false,
        });
      } else {
        setMessage({
          title: "Registration Completed Successfully",
          show: true,
          isSuccess: true,
        });
      }
    } catch (error) {}
    setSubmitting(false);
    setIsSubmitting(false);
  };

  const getRoleCode = () => {
    console.log("userInfo?.attributes", userInfo?.attributes?.role);
    if (userInfo?.attributes?.role?.at(0) === "Vessel Owner") {
      setRoleCode("vessel-owner");
    } else if (userInfo?.attributes?.role?.at(0) === "Builder") {
      setRoleCode("builder");
    } else if (userInfo?.attributes?.role?.at(0) === "Yard Owner") {
      setRoleCode("yard-owner");
    }
  };

  const registrationStyles = {
    zIndex: "99",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
  };

  const initialValues = {
    aadharNo: "",
    registrationNo: "",
    stateId: "",
    districtId: "",
    email: "",
    firstName: "",
    houseName: "",
    lastName: "",
    mobile: "",
    pinCode: "",
    postOffice: "",
    roleId: null,
    city: "",
    otp: "",
    otpId: "",
    typeOfOwnership: "",
    gstNum: "",
    aadharFile: "",
    profilePic: "",
  };
  const handleStateChange = (e) => {
    fetchDistricts({ searchParams: `stateId=${e.target.value}` });
  };

  if (isSubmitting || stateLoading) return <Loader />;

  return (
    <Container className="dashboard-container" style={registrationStyles}>
      <Container>
        <div className="d-flex justify-content-center">
          <img src="../../../assets/images/default-logo.jpg" alt="KMB LOGO" />
        </div>
        <Row>
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
          >
            {({
              errors,
              touched,
              handleChange,
              values,
              handleSubmit,
              setFieldValue,
              handleBlur,
            }) => (
              <form className="forms" onSubmit={handleSubmit}>
                <Container>
                  <CommonModal
                    {...message}
                    description=""
                    buttonText="OK"
                    handleClose={() => {
                      setMessage((message) => ({
                        ...message,
                        show: false,
                      }));
                      if (message.isSuccess) props.changeRoutes(roleCode);
                    }}
                  />
                  <Col>
                    <Row className="justify-content-center">
                      <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                        <Card className="card">
                          <Container>
                            <Row>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <SelectBox
                                  label="Type of Ownership"
                                  id="typeOfOwnership"
                                  name="typeOfOwnership"
                                  // options={typeOfOwnership}
                                  options={typeofOwner?.result ?? []}
                                  value={values.typeOfOwnership}
                                  isRequired={true}
                                  onChange={(e) => {
                                    setFieldValue("profilePic", "");
                                    setFieldValue("aadharNo", "");
                                    setFieldValue("registrationNo", "");
                                    setTypeOfOwnerShipVal(e.target.value);
                                    handleChange(e);
                                  }}
                                  error={errors.typeOfOwnership}
                                  isTouched={touched.typeOfOwnership}
                                />
                              </Col>
                              {[
                                "COOPERATIVE",
                                "COMPANY",
                                "PARTNERSHIP",
                                "CENTRAL/UNDERTAKING/DEPARTMENT",
                                "STATE/UNDERTAKING/DEPARTMENT",
                                "LOCAL/MUNICIPAL/PANCHAYAT",
                                "OTHERS",
                              ].includes(typeOfOwenership) && (
                                <>
                                  <Col
                                    Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    xxl={6}
                                  >
                                    <TextBoxLive
                                      label="GST Number/PAN/ID Number of the Head of Institution"
                                      id="gstNum"
                                      name="gstNum"
                                      type="text"
                                      placeholder="Enter"
                                      onChange={handleChange}
                                      value={values.gstNum}
                                      error={errors.gstNum}
                                      touched={touched.gstNum}
                                      // isDisabled={mode === "view"}
                                      onBlur={handleBlur}
                                      required={true} // Make it mandatory
                                    />
                                  </Col>
                                  <Col
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    xl={6}
                                    xxl={6}
                                  >
                                    <TextBoxLive
                                      label="RegistrationNo"
                                      id="registrationNo"
                                      name="registrationNo"
                                      type="text"
                                      required={true}
                                      onChange={handleChange}
                                      value={values.registrationNo}
                                      error={errors.registrationNo}
                                      touched={touched.registrationNo}
                                    />
                                  </Col>
                                </>
                              )}
                              <Col
                                Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                              >
                                <TextBoxLive
                                  label="Aadhaar"
                                  id="aadharNo"
                                  name="aadharNo"
                                  type="text"
                                  required={["INDIVIDUAL"].includes(
                                    typeOfOwenership
                                  )}
                                  onChange={handleChange}
                                  value={values.aadharNo}
                                  error={errors.aadharNo}
                                  touched={touched.aadharNo}
                                />
                              </Col>
                              {/* {values.typeOfOwnership ===
                              ownershipTypes.individual ? (
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  xxl={6}
                                >
                                  <TextBoxLive
                                    label="Aadhaar"
                                    id="aadharNo"
                                    name="aadharNo"
                                    type="text"
                                    required={true}
                                    onChange={handleChange}
                                    value={values.aadharNo}
                                    error={errors.aadharNo}
                                    touched={touched.aadharNo}
                                  />
                                </Col>
                              ) : (
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  xxl={6}
                                >
                                  <TextBoxLive
                                    label="RegistrationNo"
                                    id="registrationNo"
                                    name="registrationNo"
                                    type="text"
                                    required={true}
                                    onChange={handleChange}
                                    value={values.registrationNo}
                                    error={errors.registrationNo}
                                    touched={touched.registrationNo}
                                  />
                                </Col>
                              )} */}
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  id="houseName"
                                  label="Address"
                                  name="houseName"
                                  type="text"
                                  required={true}
                                  value={values.houseName}
                                  onChange={handleChange}
                                  error={errors.houseName}
                                  touched={touched.houseName}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <SelectBox
                                  label="State"
                                  id="stateId"
                                  name="stateId"
                                  options={stateData?.result?.data ?? []}
                                  value={values.stateId}
                                  isRequired={true}
                                  isIdSelect={true}
                                  onChange={(e) => {
                                    handleChange(e);
                                    handleStateChange(e);
                                  }}
                                  error={errors.stateId}
                                  isTouched={touched.state}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <SelectBox
                                  label="District"
                                  id="districtId"
                                  name="districtId"
                                  options={districtData?.result?.data ?? []}
                                  value={values.districtId}
                                  isRequired={true}
                                  isIdSelect={true}
                                  onChange={handleChange}
                                  error={errors.districtId}
                                  isTouched={touched.districtId}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="City"
                                  id="city"
                                  name="city"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.city}
                                  error={errors.city}
                                  touched={touched.city}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="Post Office"
                                  id="postOffice"
                                  name="postOffice"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.postOffice}
                                  error={errors.postOffice}
                                  touched={touched.postOffice}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="Pincode"
                                  id="pinCode"
                                  name="pinCode"
                                  type="text"
                                  required={true}
                                  onChange={handleChange}
                                  value={values.pinCode}
                                  error={errors.pinCode}
                                  touched={touched.pinCode}
                                />
                              </Col>

                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="Temporary Address"
                                  id="temporaryAddress"
                                  name="temporaryAddress"
                                  type="text"
                                  onChange={handleChange}
                                  value={values.temporaryAddress}
                                  error={errors.temporaryAddress}
                                  touched={touched.temporaryAddress}
                                />
                              </Col>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <TextBoxLive
                                  label="Phone Number"
                                  id="mobile"
                                  name="mobile"
                                  type="text"
                                  isRequired={true}
                                  required={true}
                                  onChange={handleChange}
                                  value={values.mobile}
                                  error={errors.mobile}
                                  touched={touched.mobile}
                                />
                              </Col>
                              <OtpButton
                                mobile={values.mobile}
                                getOtp={getOtp}
                                error={errors.mobile}
                              />

                              <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                className="pt-2"
                              >
                                <label>
                                  OTP
                                  <span className="required-indicator"></span>
                                </label>
                                <OTPInput
                                  value={values.otp}
                                  onChange={(val) => setFieldValue("otp", val)}
                                  numInputs={6}
                                  inputType="tel"
                                  renderSeparator={
                                    <span className="w-px-20"></span>
                                  }
                                  renderInput={(props) => (
                                    <input
                                      {...props}
                                      disabled={
                                        !values?.mobile ||
                                        errors?.mobile ||
                                        isGetOtpUninitialized
                                      }
                                      className="h-px-40 w-px-40 rounded-2 border border-dark-subtle"
                                    />
                                  )}
                                />
                                <ErrorMessage name="otp">
                                  {(msg) => (
                                    <span className="error-message">{msg}</span>
                                  )}
                                </ErrorMessage>
                              </Col>
                              <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                className="py-2 offset-md-6"
                              ></Col>
                            </Row>
                            <Row>
                              <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
                                <FileSelector
                                  id="aadharFile"
                                  label="Upload Aadhaar"
                                  name="aadharFile"
                                  onChange={(e) => {
                                    const { files } = e.target;
                                    if (files && files.length === 1) {
                                      setFieldValue("aadharFile", files[0]);
                                    }
                                  }}
                                  accept={"image/*,application/pdf"}
                                  required={true}
                                />
                                <ErrorMessage name="aadharFile">
                                  {(msg) => (
                                    <span className="error-message">{msg}</span>
                                  )}
                                </ErrorMessage>
                              </Col>

                              {["INDIVIDUAL"].includes(typeOfOwenership) && (
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  xl={6}
                                  xxl={6}
                                >
                                  <FileSelector
                                    id="profilePic"
                                    label="Upload your photo"
                                    name="profilePic"
                                    onChange={(e) => {
                                      const { files } = e.target;
                                      if (files && files.length === 1) {
                                        setFieldValue("profilePic", files[0]);
                                      }
                                    }}
                                    accept="image/*"
                                    required={true}
                                  />
                                  <ErrorMessage name="profilePic">
                                    {(msg) => (
                                      <span className="error-message">
                                        {msg}
                                      </span>
                                    )}
                                  </ErrorMessage>
                                </Col>
                              )}
                            </Row>
                          </Container>
                          <Container>
                            <div className="d-flex buttons-container justify-content-center p-5">
                              <Button
                                type="button"
                                label="Cancel"
                                className="btn-sucess float-right btn-outline"
                                onClick={() => {
                                  UserService.doLogout();
                                  localStorage.clear();
                                }}
                              />
                              <Button
                                type="submit"
                                label="Submit"
                                className="btn-sucess float-right btn-default"
                              />
                            </div>
                          </Container>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Container>
              </form>
            )}
          </Formik>
        </Row>
      </Container>
    </Container>
  );
}

function OtpButton({ mobile, getOtp, error }) {
  const OTP_TIMER = 2 * 60 * 1000;
  const [otpTime, setOtpTime] = useState(0);

  useEffect(() => {
    if (otpTime > 0) {
      const timerId = setInterval(() => {
        setOtpTime((prev) => prev - 1000);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [otpTime]);

  return (
    <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
      <TextBoxLive
        isDisabled={otpTime !== 0 || !mobile || error}
        value={
          otpTime === 0
            ? "Get OTP"
            : `Resend otp in ${millisToMinutesAndSeconds(otpTime)}`
        }
        label="&nbsp;"
        type="button"
        onClick={() => {
          if (!mobile) return;
          getOtp({
            data: {
              phone: mobile,
            },
          }).then((_) => {
            setOtpTime(OTP_TIMER);
          });
        }}
      />
    </Col>
  );
}

export default Index;
